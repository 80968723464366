<template>
    
    <v-form ref="ticket">
      <base-material-card icon="mdi-ticket-confirmation">
        <slot slot="title">Tickets</slot>
        <slot slot="rightButtons" v-if="orderItem">
          <toggle-button text="Add a new Ticket" openIcon="mdi-plus" @toggle="showCreateTicketToggle = !showCreateTicketToggle" :showing="showCreateTicketToggle"/>
        </slot>
        <div v-if="showCreateTicketToggle">

          <div v-if="events && events.length > 1">
            <v-select label="Event" menu-props="auto" item-value="id" item-text="name" v-on:change="reloadEvent" v-model="ticket.eventId" :items="events">
            </v-select>
          </div>

          <div v-if="eventTimes && eventTimes.length > 1 || reloadEventFlag">
            <v-select label="Event Time" menu-props="auto" item-value="id" v-model="ticket.eventTimeId" :items="eventTimes">
              <template v-slot:item="{ item }">{{utils.dateToShortString(item.start)}}</template>
              <template v-slot:selection="{ item }">{{utils.dateToShortString(item.start)}}</template>
            </v-select>
          </div>

          <div v-if="ticketTypes && ticketTypes.length > 1 || reloadEventFlag">
            <v-select label="Ticket Type" menu-props="auto" item-value="id" item-text="name" v-model="ticket.ticketTypeId" :items="ticketTypes">
            </v-select>
          </div>

          <v-btn color="success" class="mb-10" @click="createTicket">Create Ticket</v-btn>

        </div>
        <v-row class="mx-n1 underlinedRow">
          <v-col cols="3">Number</v-col>
          <v-col cols="3">Event</v-col>
          <v-col cols="2">Time</v-col>
          <v-col cols="2">Type</v-col>
          <v-col cols="2">Total Price</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticket, i) in tickets" :key="i" @click="selectItem(ticket)">
              <v-col cols="3">{{ticket.ticketNumber}}</v-col>
              <v-col cols="3">{{ticket.eventName}}</v-col>
              <v-col cols="2">{{utils.dateToShortString(ticket.eventTimeStart)}}</v-col>
              <v-col cols="2">{{ticket.ticketTypeName}}</v-col>
              <v-col cols="2">{{utils.priceDisplay(ticket.price + ticket.bookingFee)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {
    name: 'TicketList',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  

    props: {
      order: null,
      orderItem: null,
    },

    data () {
      return {
        showCreateTicketToggle: false,
        event: {},
        boxOffice: {},
        tickets: {},
        ticket: {},
        eventTimes: [],
        events: [],
        reloadEventFlag: false,
        ticketTypes: [],
      }
    },

    created() {
      this.load();
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      async reloadEvent(id) {
        this.reloadEventFlag = true;
        this.ticket.eventTimeId = null;
        this.ticket.ticketTypeId = null;
        this.loadEvent(id);
      },

      async loadEvent(id) {
        this.event.id = id;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
      },

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", {id:this.order.boxOfficeId});
        this.events = await Api.post(this, "Event", "list", this.boxOffice);
        this.statuses = await Api.post(this, "Order", "statuses");
        this.listTickets();
      },

      async listTickets() {
        if (this.orderItem) {
          this.tickets = await Api.post(this, "Ticket", "list", {orderItemId: this.orderItem.id});
        } else {
          this.tickets = await Api.post(this, "Ticket", "list", {orderId: this.order.id});
        }
      },

      async createTicket() {
        if (! await Utils.validate(this.$refs.ticket) ) {
          return;
        }
        this.ticket.boxOfficeId = this.order.boxOfficeId;
        this.ticket.orderId = this.order.id;
        this.ticket.orderItemId = this.orderItem.id;
        this.ticket = await Api.post(this, "Ticket", "create", this.ticket);
        this.load();
        this.showCreateTicketToggle = false;
      },

      async deleteTicket(ticket) {
        await Api.post(this, "Ticket", "delete", ticket);
        this.listTickets();
        this.loadOrder();
      },

      selectItem(ticket) {
        this.$router.push("/Dashboard/Ticket/" + ticket.id)
      },

    }
  }
</script>
